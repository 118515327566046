.App {
  text-align: left;
}
.Container-Main {
  text-align: center;
  width: 100%; 
  /* padding-top: 1em; */
}
.App_body {
  text-align: left;
  width: 80%;
  padding: 1em;
  padding-top: 0em;
}

.App_li {
  text-align: left;
  width: 80%;
}

.App_li_parent {
 
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.App_center {
  /* text-align: center;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
   */
  box-shadow: 0 3px 6px rgba(0,0,0,0.25), 0 3px 3px rgba(0,0,0,0.22);
}

.li_center {
  text-align: center;
  width: 80%;
}

.title_center
{  
  text-align: center;
}

.App-logo {
  /* height: 15vmin; */
  width: 60%;
  pointer-events: none;
}


.App-footer {
  /* height: 15vmin; */
  width: 100%;
  pointer-events: none;
}

.App-img-service {
  /* height: 15vmin; */
  width: 4vmin;
  pointer-events: none;
  vertical-align: middle;
}
.App-img-accordion
{
   width: 3vmin;
  pointer-events: none;
  vertical-align: middle;
  margin-right: 1vmin;
}


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: white;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(6px + 2vmin);
  color: #63666F;
  /* background-image: trasparent url(CNH_TransparentBackground.png);
  background-repeat: repeat,no-repeat; */
}

.App-link {
  color:  #63666F;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
}

.Service-Desk
{
  color: #C3231E;
  margin-top: calc(2px + 2vmin);
  margin-left: calc( 2vmin);  
  padding-top: calc(0px + 2vmin);
  margin-bottom: 0px;
}

.App_p1{

  margin-left: calc( 2vmin);  
  margin-top: 10px;
  margin-bottom: 10px;
}

.App_p2{

  margin-left: calc( 2vmin);  
  margin-top:0px;
  margin-bottom: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


fluent-accordion-item div.panel {
  padding: 12px 10px 12px;
  margin: 12px 0 12px; 
 
}


div.panel2 {
  padding: 12px 10px 12px;
  margin: 12px 0 12px;
  font-size: calc(2px + 2vmin);
  color: #63666F;
 width: 80%;
}


div.panel_onboard {
  padding: 12px 10px 12px;
  margin: 12px 0 12px;
  font-size: calc(2px + 2vmin);
  color: #63666F; 
 box-shadow: 0 3px 6px rgba(0,0,0,0.25), 0 3px 3px rgba(0,0,0,0.22);
}

.accordion-em{
  font-size: calc(2px + 2vmin);
  color: #63666F;
 
}

.box-shadow {
  margin-bottom: 12px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.25), 0 3px 3px rgba(0,0,0,0.22);
}

.div_button
{ margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items:flex-end;
  justify-content:center;
}


.button{
  color: white;
  background-color: #c3231e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  
}


.div_button_opposite
{ margin-top: 12px;
  display: flex; 
 
  justify-content:space-between;
  
}
.button_opposite{
  color: white;
  background-color: #c3231e;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 10%;
  float: left; */
}
 
.button_opposite:hover {
  background-color: #c3231e;
}


.button:hover {
  background-color: #b9231d;
  color: rgb(234, 234, 234);
}

.button_disables{
  color: white;
  background-color: gray; 
}

.div_label
{
  display: inline-block;
  margin-right: 10px;
  text-align:right;
  width: 30%;

}


.div_data
{    
  display: inline-block;
  margin-right: 10px;
  text-align:right;
  width: 40%;
}

.div_label_summary
{
  display: inline-block;
  margin-right: 10px;
  text-align:right;
  width: 40%;

}

.div_data_summary
{    
  display: inline-block;
  margin-right: 10px; 
  width: 40%;
}

.div_data_summary_badge
{    
  display: inline-block;
  margin-right: 10px;
  width: fit-content;
}

p.privacyCheck
{
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
  
}
/* scale by a factor of 2 */
p.privacyCheck > input[type="checkbox"]
{
  margin-right:  1em;
  margin-left: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);

}

.div_captcha
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.error
{
  text-align: center;
  color:  red;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin); 
}

.submitted
{
  text-align: center;
  /* color:  red; */
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin); 
}